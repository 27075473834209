import type { ApiResponse } from "./../../../common/types/ApiResponse";
import { message } from "antd";
import { useMutation, useQuery } from "react-query";
// import { FetchDetailMyCourses } from "../apis/myCourses";
import httpRequest from "../../../config/config";
import type { SkillDetail } from "../types/common";

const fetchCourseDetail = (
  id: string | undefined
): Promise<ApiResponse<SkillDetail>> => {
  return httpRequest.get(`/school/my-courses/${id}`);
};

const uploadIntroVideo = (
  course_id: string,
  video_file_name: string
): Promise<ApiResponse<string>> => {
  return httpRequest.post(`/school/my-courses/${course_id}/intro-video`, {
    video_file_name,
    video_has_audio: true,
  });
};

const deleteIntroVideo = (course_id: string): Promise<ApiResponse<string>> => {
  return httpRequest.delete(`/school/my-courses/${course_id}/intro-video`);
};

const updateIntroVideo = (
  course_id: string,
  video_file_name: string
): Promise<ApiResponse<string>> => {
  return httpRequest.put(`/school/my-courses/${course_id}/intro-video`, {
    video_file_name,
    video_has_audio: true,
  });
};

export const useFetchDetailMyCourses = (id: string | undefined) => {
  const handleFetchError = (error: string) => {
    message.error(
      `An error occurred while fetching skill and courses: ${error}`
    );
  };

  return useQuery(
    ["detail-skill-and-courses", id],
    () => fetchCourseDetail(id),
    {
      refetchOnWindowFocus: false,
      onError: handleFetchError,
      enabled: !!id,
    }
  );
};

export const useUploadIntroVideo = () => {
  const handleFetchError = (error: string) => {
    message.error(`An error occurred while uploading intro video: ${error}`);
  };

  return useMutation(
    ({
      course_id,
      video_file_name,
    }: {
      course_id: string;
      video_file_name: string;
    }) => uploadIntroVideo(course_id, video_file_name),
    {
      onSuccess: () => {
        message.success("Intro video uploaded successfully");
      },
      onError: handleFetchError,
    }
  );
};

export const useDeleteIntroVideo = () => {
  const handleFetchError = (error: string) => {
    message.error(`An error occurred while deleting intro video: ${error}`);
  };

  return useMutation((course_id: string) => deleteIntroVideo(course_id), {
    onSuccess: () => {
      message.success("Intro video deleted successfully");
    },
    onError: handleFetchError,
  });
};

export const useUpdateIntroVideo = () => {
  const handleFetchError = (error: string) => {
    message.error(`An error occurred while updating intro video: ${error}`);
  };

  return useMutation(
    ({
      course_id,
      video_file_name,
    }: {
      course_id: string;
      video_file_name: string;
    }) => updateIntroVideo(course_id, video_file_name),
    {
      onSuccess: () => {
        message.success("Intro video updated successfully");
      },
      onError: handleFetchError,
    }
  );
};
