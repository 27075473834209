import React from "react";
import EditLevelCourses from "./EditLevelCourses";
import { AutoSaveContextProvider } from "../contexts/AutoSaveContext";
import { SkillAndLevelContextProvider } from "../contexts/SkillAndLevelContext";

const EditLevelCoursesContainer = () => {
  return (
    <AutoSaveContextProvider>
      <SkillAndLevelContextProvider>
        <EditLevelCourses />
      </SkillAndLevelContextProvider>
    </AutoSaveContextProvider>
  );
};

export default EditLevelCoursesContainer;
